<template>
    <div class="main-container">
        <img :src="require('../assets/bg2.jpg')" class="bg">
        <div id="loading" v-if="showLoading">
            <div class="content">
        
                <v-icon name="spinner" pulse scale="3" />

            </div>
        </div>
        <div class="d-flex flex-wrap wrapper">
            <div class="sidebar" v-if="sideBar">
                <div class="align-items-end justify-content-end text-right text-white">
                    <h4 style="cursor:pointer;" @click="openSidebar">x</h4>
                </div>
                <div class="text-center">
                    <img :src="require('../assets/id-logo.png')" style="margin-bottom:40px;width:15em;" class="mx-auto text-center">
                </div>
                <div class="sidebar-link d-flex flex-wrap " :class="{active: modalState == 'main'}" @click="exitModal">
                    <img :src="require('../assets/icon-dash.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Dashboard</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" @click="profile">
                    <img :src="require('../assets/profile.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Profile</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" @click="transactions">
                    <img :src="require('../assets/transactions.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Transactions</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'changePin'}" @click="changePin">
                    <img :src="require('../assets/change pipn.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Change Pin</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap">
                    <img :src="require('../assets/support.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Support</h4>
                </div>
            </div>
            <div class="main-body">
                <div class="topbar d-flex flex-wrap justify-content-between mb-5">
                    <div class="d-flex justify-content-between">
                        <button class="sidebar-toggler text-white my-auto mr-3" type="button"  @click="dashboard">
                            <v-icon name="chevron-right" />
                        </button>
                        <input class="form-control mr-sm-2 search-bar my-auto" type="search" placeholder="Search Profile ID" aria-label="Search">
                    </div>
                    <div class="left-section d-flex flex-wrap justify-content-end align-items-center text-white">
                        <h6 class="my-auto" style="margin-right:3em;cursor:pointer;" @click="logout">Logout</h6>
                        <img :src="require('../assets/notif.png')" alt="" class="topbar-img my-auto">
                    </div>
                </div>
                <div class="main-wallet" v-if="state == 'main-wallet'">
                    <h3>List of data assets:</h3>
                    <form class="mt-4 text-white">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.SELFIE" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Face
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.HOMENUMBER" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Home Number
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.DRIVERSLICENSE" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Driver’s License
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.PASSPORT" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Passport
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.POA" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Proof of Address
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.EMPLOYMENT" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Employment
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.HOMEASSET" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Home Asset
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.VEHICLEASSET" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Vehicle Asset
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.EDUCATION" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Education
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.GENDER" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Gender
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="dataAssets.OTHER" id="defaultCheck1" @change="show">
                            <label class="form-check-label" for="defaultCheck1">
                                Other
                            </label>
                        </div>
                    </form>
                    <div class="message-border mt-5">
                        <div class="message text-white text-center">
                            <h6>I hereby understand that I am sharing these information 
                            via this link to get verification from IDChain community.</h6>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between mt-5" style="width:32em;">
                        <button class="btn btn-main" style="width:15em;" @click="generateKey" v-if="showButton">Share</button>
                        <button class="btn btn-cancel" style="width:15em;" @click="dashboard">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <ChangePin/>
        <ConfirmLogout />
        <GenerateKey :sharingKey="key"/>
    </div>
</template>

<script>
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
import GenerateKey from '@/components/modal/GenerateKey.vue'
export default {
    components: {
        ChangePin,
        ConfirmLogout,
        GenerateKey
    },
    data() {
        return {
            sideBar: false,
            state: 'main-wallet',
            showLoading: false,
            dataAssets: {
                SELFIE: false,
                HOMENUMBER: false,
                DRIVERSLICENSE: false,
                PASSPORTNUMBER: false,
                POA: false,
                HOMEASSET: false,
                VEHICLEASSET: false,
                OTHER: false,
                GENDER: false, 
            },
            key: "",
            showButton: false
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        formatAmount:  function (num) {
            if(isNaN(num)) {
            num = 0;
            }
            return parseFloat(num).toFixed(8)
        },
        dashboard() {
            this.$router.push('/')
        },
        formatDate: function(time){
            return new Date(time).toLocaleString();
        },
        show() {
            this.showButton = true
        },
        generateKey() {
            var dataString = ""

            for (var key in this.dataAssets) {
                if (this.dataAssets.hasOwnProperty(key)) {
                    if(this.dataAssets[key] == true) {
                        dataString = dataString + key + ", "
                    }
                }
            }

            console.log(dataString.substring(0, dataString.length - 2))
            const params = {
                data: dataString.substring(0, dataString.length - 2)
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');

            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/chain/generatekey?' + data,
            };
            this.axios(options)
                .then((response) => {
                   this.key = window.location.href.replace('sharing', 'data-assets/') + response.data.tokenId
                   console.log(this.key)
                   this.$bvModal.show('generate-key-modal'); 
                })
                .catch((error) => {
                    
                });
        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        checkSession: function() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/login";
                }  

                if(err.response.data.msgCode == '00020') {
                    setTimeout( () => {
                    window.location.href = "/setup-profile";
                },1000)
                    
                }  

                })
        },
    },
    mounted() {
        this.checkSession();
        let socketScript = document.createElement('script')
            socketScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js')
            document.head.appendChild(socketScript)
    }
}
</script>

<style scoped lang="scss">
    .wrapper {
        position:relative;
        .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100vh;
            z-index:2;
            .sidebar-link {
                margin:20px auto;
                width: 15em;
                cursor:pointer;
                &.active {
                    border-right:2px #0e649e solid;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
        .key-choices {
            position: absolute;
            background: #fff;
            color: #333;
            top: 60px;
            overflow: auto;
            z-index: 3;
            width:100%;
            border-radius:10px;
            .choice {
                width:100%;
                padding:10px;
                &:hover {
                    background-color:blue;
                    color:white;
                }
            }
        }
        .main-body {
            width:100%;
            padding:50px 70px;
            .sidebar-toggler {
                background:none;
                width:2.5em;
                height:2.5em;
                border:#fff 1px solid;
                border-radius:5px;
                fill:white;
            }
            .search-bar {
                width:20em;
            }
            .left-section {
                .topbar-img {
                    object-fit: contain;
                }
                .profile {
                    .profile-name {
                        color:white;
                        margin-bottom:0;
                        font-family: 'SSN-Bold';
                    }
                    .profile-email {
                        color:white;
                        font-family: 'SSN-Light';
                        margin-bottom:0;
                    }
                }
            }
            .tabs {
                width: 50em;
                .tab {
                    cursor:pointer;
                    color:#fff;
                    font-family:SSN-Roman;
                    &.active {
                        color:#0567d7;
                    }
                    h6 {
                        margin-bottom:2px;
                    }
                }
                .active-circle {
                    width:10px;
                    height:10px;
                    background: #0567d7;
                    border-radius:50%;
                }
            }
            .main-wallet {
                width:100%;
                h3 {
                    color:#3297db;
                }
                .message-border {
                    width:30em;
                    background:linear-gradient(to right, #0e649e, #fff700, #9e0e0e);
                    border-radius:5px;
                    padding:2px;
                    .message {
                        background:#062035;
                        padding:15px;
                        border-radius:5px;
                    }
                }
                .btn-cancel {
                    border:2px #9e0e0e solid;
                    color:white;
                    border-radius:5px;
                }
                .history {
                    width:25em;
                    padding:15px 30px;
                    background-image: linear-gradient(to right, #110737, #150754);
                    border-radius:10px;
                    .transactions {
                        width:30em;
                        .transaction {
                            background: rgba(125, 125, 125, 0.5);
                            padding:15px;
                            border-radius:10px;
                            .dot {
                                object-fit:contain;
                                width:15px;
                                height:15px;
                            }
                            .transaction-info {
                                font-family:SSN-Roman;
                                .hash {
                                    white-space: nowrap !important;
                                    width: 20em;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>